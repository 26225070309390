import {
  Box,
  BoxSx,
  Button,
  DEFAULT_ICONS,
  Divider,
  Icon as UiKitIcon,
  Link,
  List,
  ListItem,
  PICTORIAL_ICONS,
  PictorialIcon,
  Text,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { useIcon } from "@/hooks/useIcon";
import { BlockTextProps } from "@/types/BlockText";

export const BlockText: React.FC<BlockTextProps> = (blockTextProps) => {
  const { themeOverrideMode } = blockTextProps;
  return themeOverrideMode ? (
    <ThemeOverride mode={themeOverrideMode}>
      <RenderBlockText {...blockTextProps} />
    </ThemeOverride>
  ) : (
    <RenderBlockText {...blockTextProps} />
  );
};

/* eslint-disable complexity */
const RenderBlockText: React.FC<BlockTextProps> = ({
  align,
  alignY,
  alt,
  as,
  backgroundColor,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  boxShadow,
  captions,
  color,
  display,
  flexDirection,
  fontFamily,
  fontVariantNumeric,
  gap,
  grow,
  height,
  href,
  icon,
  iconPosition,
  iconSize,
  iconVariant,
  justifyContent,
  maxHeight,
  maxWidth,
  mb,
  minHeight,
  minWidth,
  mt,
  overflowWrap,
  poster,
  shrink,
  size,
  spacingBottom,
  spacingEnd,
  spacingStart,
  spacingTop,
  src,
  target,
  text,
  underline,
  variant,
  weight,
  whiteSpace,
  wordBreak
}) => {
  const Icon = useIcon(iconVariant);
  const styles: BoxSx = {
    alignItems: as === "flex" ? alignY : null,
    backgroundColor,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    boxShadow,
    display: as === "flex" ? "flex" : display,
    flexGrow: grow,
    flexShrink: shrink,
    flexDirection,
    fontFamily,
    fontVariantNumeric,
    fontWeight: weight || "inherit",
    gap: as === "flex" ? gap : null,
    height,
    justifyContent: as === "flex" ? (justifyContent ? justifyContent : align) : null,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    mb,
    mt,
    paddingInlineEnd: spacingEnd,
    paddingInlineStart: spacingStart,
    pb: spacingBottom,
    pt: spacingTop,
    textAlign: align,
    whiteSpace,
    overflowWrap: overflowWrap ? overflowWrap : "break-word",
    wordBreak,
    ...((color && { color }) || {}),
    ...(grow === "fill" && {
      "> *": {
        flex: 1
      }
    })
  };

  const renderText = React.useCallback(
    () =>
      Array.isArray(text) ? (
        text.map((textNode, i) => <BlockText key={i} {...textNode} />)
      ) : (
        <>{text}</>
      ),
    [text]
  );

  if (as === "br") {
    return <Box as="br" sx={{ display: ["none", "none", "block"], ...styles }} />;
  } else if (as === "em") {
    return (
      <Box as="em" sx={styles}>
        <em>{renderText()}</em>
      </Box>
    );
  } else if (as === "sup") {
    return (
      <Box as="sup" sx={{ verticalAlign: "super", fontSize: () => "55%" }}>
        {renderText()}
      </Box>
    );
  } else if (as === "sub") {
    return (
      <Box as="sub" sx={{ verticalAlign: "sub", fontSize: () => "55%" }}>
        {renderText()}
      </Box>
    );
  } else if (as === "a") {
    return (
      <Link
        href={href}
        icon={icon as keyof typeof DEFAULT_ICONS}
        iconPosition={iconPosition}
        sx={styles}
        target={target}
        variant={underline ? "underline" : "default"}
      >
        {renderText()}
      </Link>
    );
  } else if (as === "ordered" || as === "unordered" || as === "unordered-accent") {
    return (
      <Box sx={styles}>
        <List variant={as} size={size}>
          {renderText()}
        </List>
      </Box>
    );
  } else if (as === "icon") {
    return (
      <Box
        sx={{
          ...styles,
          // @ts-ignore
          "> svg": { height: "100%" }
        }}
      >
        {Icon ? (
          <Icon />
        ) : (
          icon && <UiKitIcon icon={icon as keyof typeof DEFAULT_ICONS} size={iconSize} />
        )}
      </Box>
    );
  } else if (as === "pictorial-icon") {
    return <Box sx={styles}>{<PictorialIcon icon={icon as keyof typeof PICTORIAL_ICONS} />}</Box>;
  } else if (as === "li") {
    return <ListItem sx={{ fontVariantNumeric: "tabular-nums" }}>{renderText()}</ListItem>;
  } else if (as === "img") {
    return (
      <Box
        alt={alt || ""}
        as="img"
        role={!alt ? "presentation" : undefined}
        src={src}
        sx={styles}
      />
    );
  } else if (as === "button") {
    return (
      <Box sx={styles}>
        <Button
          icon={icon as keyof typeof DEFAULT_ICONS}
          iconPosition={iconPosition}
          variant={variant}
          href={href}
        >
          {renderText()}
        </Button>
      </Box>
    );
  } else if (as === "video") {
    return (
      <Box sx={styles}>
        <video
          controls={true}
          poster={poster}
          style={{ width: "100%", height: "auto", borderRadius: 8 }}
        >
          <source src={src} />
          {captions?.map((caption) => (
            <track
              key={caption.label}
              label={caption.label}
              kind="subtitles"
              srcLang={caption.language}
              src={caption.source}
            />
          ))}
        </video>
      </Box>
    );
  } else if (as === "divider") {
    return (
      <Box sx={styles}>
        <Divider />
      </Box>
    );
  } else if (as === "fragment") {
    return <>{renderText()}</>;
  }

  return (
    <Text as={as === "flex" ? "div" : as} size={size} sx={styles}>
      {renderText()}
    </Text>
  );
};
